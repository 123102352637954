import {Navigate, useRoutes} from 'react-router-dom';
// config
import {PATH_AFTER_LOGIN} from 'src/config-global';
//
import {mainRoutes} from './main';

import {authRoutes} from './auth';
import {dashboardRoutes} from './dashboard';
import {registersRoutes} from './registers';
import {employeesRoutes} from './employees';
import {transportsRoutes} from './transports';
import {createBrowserHistory} from 'history';
import {usePermission} from 'src/hooks/use-permission';
import {useSelector} from 'react-redux';

// ----------------------------------------------------------------------

const history = createBrowserHistory()

const router = function Router() {
  const {isApprover, isCreator} = usePermission()
  const permissions = useSelector(store => store.user?.permissions)

  const role = permissions?.user_data?.role

  const bidBlocks = useSelector(state => state.app.settings.bidBlocks)

  const isAuto = bidBlocks?.auto?.display

  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Transports routes
    ...(isAuto ? transportsRoutes : []),

    // Registers routes
    ...((isCreator || isApprover) ? registersRoutes : []),

    //Employees Routes
    ...((role === 'merchant-admin') ? employeesRoutes : []),

    // Main routes
    ...mainRoutes,

    // No match 404
    {path: '*', element: <Navigate to="/operations" replace />},
  ]);
}
export default router
export {router}
